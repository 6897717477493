import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export const timelineHero = () => {
    


    // ScrollTrigger.create({
    //     animation: tl,
    //     trigger: ".m-section-hero",
    //     // toggleActions:"play reverse reverse  reverse",
    //     // start: "0% 0%",
    //     //markers: true,
    //     scrub:true
    // });
    // let tlscrub = gsap.timeline()
    // tlscrub.to(
    //     ".m-section-floater .graphic1",
    //     {
    //         duration: 0.5,
    //         x: 600,
    //         y: - mheight,
    //         rotate: -350,
    //         scale: 0.7,
    //     },
    //     "<"
    // );

    // ScrollTrigger.create({
    //     animation: tl.reverse(),
    //     trigger: ".m-section-how",
    //     start: "0% 10%",
    //     //scrub:3,

    // });

    //tl.scrollTrigger.labelToScroll("myLabel")
};







export const timelineFaq = () => {
    // ScrollTrigger.create({
    //     animation: tl,
    //     trigger: ".m-section-faq",
    //     start: "top top",
    //     scrub: 4,
    //     pin: true,
    //     //markers: true,
    // });
};

export const timelineTrending = () => {
    // ScrollTrigger.create({
    //     trigger: ".m-section-trending",
    //     start: "top top",
    //     scrub: 4,
    //     pin: true,
    //     //markers: true,
    // });
};

export const timelineCreate = () => {
    let mheight = document.querySelector(".m-section").offsetHeight;

    let tl = gsap.timeline();

    tl.from(
        ".m-section-create .graphic1",
        {
            duration: 0.5,
            x: 600,
            y: -mheight,
            rotate: -350,
            scale: 0.7,
        },
        "<"
    );

    tl.from(
        ".m-section-create .graphic2",
        {
            duration: 0.5,
            x: -620,
            y: -mheight,
            rotate: -345,
            scale: 0.7,
        },
        "<"
    );
    tl.from(
        ".m-section-create .graphic3",
        {
            duration: 1,
            x: 600,
            y: mheight,
            rotate: 350,
            scale: 0.7,
        },
        "<"
    );
    tl.from(
        ".m-section-create .box1",
        {
            duration: 1,
            x: -400,
            y: -mheight,
            rotate: -25,
            scale: 1.5,
        },
        "<"
    );

    tl.from(
        ".m-section-create .box2",
        {
            duration: 1,
            x: 200,
            y: mheight,
            rotate: 10,
            scale: 2,
        },
        "<"
    );
    tl.from(
        ".m-section-create .box3",
        {
            duration: 1,
            x: 500,
            y: -mheight,
            rotate: 15,
            scale: 1.5,
        },
        "<"
    );

    ScrollTrigger.create({
        animation: tl,
        trigger: ".m-section-create",
        toggleActions: "restart pause reverse reverse",
        start: "top 50%",
        ease: "power3.out",
        // scrub: 3,
        //pin: true,
        //markers: true,
    });
};
