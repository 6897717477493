import axios from "axios";
import endpoint from "./endpoints";
import { getStoredUser } from "src/components/auth/auth";
import { handleResponse, handleRes, handleErr } from "./response";
import { v4 as uuidv4 } from "uuid";
import { getS3 } from "./s3";
//QUERIES

export const imageMaker = async (url) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "imagemaker",
                    act: "resize",
                    url: url,
                    pagesize: "1",
                },
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const publish = async (channel, message) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "publish",
                    act: "publish",
                    channel: channel,
                    message: message,
                    pagesize: "1",
                },
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const llmapi = async (message) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "openai",
                    act: "llmapi",
                },
                Values: {
                    prompt: message,
                },
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const insertKnowledge = async (input) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            input.userid = getStoredUser().id;
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "llmlibrary",
                    act: "insert",
                },
                Values: input,
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const listKnowledge = async ({ modelid, type }) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "llmlibrary",
                    act: "select",
                    pagesize: "30",
                },
                Where: {
                    modelid: modelid,
                    userid: getStoredUser().id,
                    bucket: type === "appearance" ? "avatarz" : "",
                },
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const deleteKnowledge = async (knowledgeid) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "llmlibrary",
                    act: "delete",
                },
                Where: {
                    userid: getStoredUser().id,
                    id: knowledgeid,
                },
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const upload = async ({ file, networkid, channelid, network, channel, postprocess, useAdminAuth }) => {
    const theAuthUser = {
        id: getStoredUser()?.id,
        auth: getStoredUser()?.auth,
    };

    if (useAdminAuth === true) {
        theAuthUser.id = `11111111-1111-1111-1111-111111111111`;
        theAuthUser.auth = `xxxx`;
    }
    if (theAuthUser.id && theAuthUser.auth && file) {
        const formData = new FormData();
        // formData.append(`userid`, theAuthUser.id);
        // formData.append(`auth`, theAuthUser.auth);
        // formData.append(`directory`, directory ? directory : "");
        // formData.append(`segment`, segment ? segment : "");
        // formData.append(`uploadfile`, file);

        formData.append(`userid`, theAuthUser.id);
        formData.append(`auth`, theAuthUser.auth);

        formData.append(`uploadfile`, file);

        formData.append(`networkid`, networkid ? networkid : "");
        formData.append(`channelid`, channelid ? channelid : "");

        formData.append(`network`, network ? network : "");
        formData.append(`channel`, channel ? channel : "");

        if (network === "inputvideo" || network === "inputimage" || network === "inputaudio") {
            formData.append(`filetype`, network);
        }

        if (file && file.name) {
            let fname = file.name.split(".")[0];
            let fext = file.name.split(".")[1];

            formData.append(`newfilename`, `${fname}_${uuidv4()}.${fext}`);
        }
        if (postprocess) {
            formData.append(`postprocess`, postprocess);
        }

        let res;
        try {
            res = await axios.post(`${endpoint.upload}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
        } catch (error) {
            console.log("upload error :", error);
        }

        return res;
    } else {
        return "no permission";
    }
};

// export const upload = async ({ file, segment, directory, postprocess }) => {
//     if (getStoredUser() && getStoredUser().id) {
//         let res;
//         try {
//             const formData = new FormData();
//             formData.append(`userid`, getStoredUser().id);
//             formData.append(`auth`, getStoredUser().auth);
//             formData.append(`directory`, directory ? directory : "");
//             formData.append(`segment`, segment ? segment : "");
//             formData.append(`uploadfile`, file);
//             if (file && file.name) {
//                 let fname = file.name.split(".")[0];
//                 let fext = file.name.split(".")[1];

//                 formData.append(`newfilename`, `${fname}_${uuidv4()}.${fext}`);
//             }

//             if (postprocess) {
//                 formData.append(`postprocess`, postprocess);
//             }

//             res = await axios.post(`${endpoint.upload}`, formData, {
//                 headers: {
//                     "Content-Type": "multipart/form-data",
//                 },
//             });
//         } catch (error) {
//             console.log("upload error :", error);
//         }

//         return res;
//     }
// };

export const intiModelGenerator = async ({ name, modelid, src }) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "ai",
                    act: "preprocdata",
                    pagesize: "1",
                },
                Values: {
                    name: name,
                    modelid: modelid,
                    userid: getStoredUser().id,
                    src: src,
                },
            };

            const res = await axios.post(endpoint.rest, query);
            //const res = await axios.post('https://luka.ngrok.app/api', query);
            console.log("model gen response:", res.data);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const addToQueue = async ({ key, value }) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: "11111111-1111-1111-1111-111111111111",
                    auth: "xxxx",
                    sys: "queue",
                    act: "add",
                    pagesize: "1",
                },
                Values: {
                    userid: "11111111-1111-1111-1111-111111111111",
                    key: key,
                    value: value,
                },
            };

            const res = await axios.post(endpoint.rest, query);
            //console.log(res)
            if (key === "lipsynchd") {
                let counter = 0;
                let queueState = new Promise((resolve, reject) => {
                    const interval = setInterval(async () => {
                        const checkQue = await getQueue({ id: res.data.result[0].id });
                        console.log("checkQue ::", checkQue);

                        if (checkQue && checkQue.state === "complete") {
                            clearInterval(interval);
                            resolve(checkQue.result);
                        }

                        counter++;

                        if (counter === 36) {
                            clearInterval(interval);
                            resolve("timedout");
                        }
                    }, 5000);
                });

                return queueState;
            } else {
                return handleResponse(query, res);
            }
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const getQueue = async (input) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: "11111111-1111-1111-1111-111111111111",
                    auth: "xxxx",
                    sys: "queue",
                    act: "select",
                    pagesize: "1",
                },
                Where: input,
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const captureKeyframes = async (input) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: "xxxx",
                    sys: "crawl",
                    act: "keyframe",
                    pagesize: "1",
                },
                Values: input,
            };

            const res = await axios.post(endpoint.rest, query);
            let keyRes = handleResponse(query, res);
            //   console.log("keyRes res : ", keyRes);
            if (keyRes && keyRes.dir) {
                const uploadRes = await uploadKeyframes(keyRes.dir);
                // console.log("upload keyframe res:", uploadRes);
                if (uploadRes && uploadRes.status && uploadRes.status.indexOf("Uploaded") !== -1) {
                    let thedir = keyRes.dir.split("tmp/")[1];
                    let getS3res = await getS3({ userid: getStoredUser().id, directory: `${thedir}` });
                    //console.log('getS3res : ',getS3res)
                    return getS3res;
                } else {
                    return uploadRes;
                }
            }
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const uploadKeyframes = async (dir) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: "xxxx",
                    sys: "hls",
                    act: "uploaddir",
                    pagesize: "1",
                },
                Values: {
                    dir: `${dir}`,
                },
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const weatherVideo = async (data) => {
    // const token = getStoredUser()?.token;//
    const token ='EkisLxhVNVPHQA' // +1 9178087351

    const config = {
        method: "post",
        url: `${endpoint.rest}generate/weathervideo`,
        data,
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};
