import { ReactLenis, useLenis } from "@studio-freight/react-lenis";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import Iicon from "src/components/iicon/iicon";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { FaArrowUp } from "react-icons/fa6";

import { FaCheck } from "react-icons/fa";
import Header from "src/layouts/header/header";

import "src/pages/marketing/marketing.css";
import "./profile.css";

import person1 from "src/assets/images/person-1.png";
import person2 from "src/assets/images/person-2.png";
import person3 from "src/assets/images/person-3.png";
import person4 from "src/assets/images/person-4.png";
import person5 from "src/assets/images/person-5.png";
import person6 from "src/assets/images/person-6.png";
import person7 from "src/assets/images/person-7.png";
import person8 from "src/assets/images/person-8.png";
import person9 from "src/assets/images/person-9.png";
import person10 from "src/assets/images/person-10.png";
import person12 from "src/assets/images/person-12.png";
import person15 from "src/assets/images/person-15.png";
import person16 from "src/assets/images/person-16.png";

import arrowDown from "src/pages/marketing/images/arrow-accordion.svg";
import graphicChat from "src/pages/marketing/images/graphic-chat.svg";
import graphicThumb from "src/pages/marketing/images/graphic-thumb.svg";
import graphicPic from "src/pages/marketing/images/graphic-pic.svg";

import girl1 from "src/assets/vids/girl1.mp4";
import male1 from "src/assets/vids/male1.mp4";

import MarketingFooter from "../footer/footer";

import { timelineHero, timelineFaq, timelineTrending, timelineCreate } from "./animations/animation";
import Menu from "src/components/menu/menu";
import { animatePhone } from "./animations/phone";
import { animateMembers } from "./animations/members";
import { animateConnect } from "./animations/connect";
import Card from "./components/card";

import TypingEffect from "src/components/typing";
import Callout from "./components/callout";
import Popup from "src/layouts/popup/popup";
import FormTalk from "./components/form-talk";


import messiHero from "src/assets/images/messi-hero.jpg";
import messiSmall from "src/assets/images/messi-small.jpg";

import messiVid1 from "src/assets/vids/messi-vid-1.mp4";
import messiVid2 from "src/assets/vids/messi-vid-2.mp4";
import messiVid3 from "src/assets/vids/messi-vid-3.mp4";
import messiVidTalk from "src/assets/vids/messi-vid-talk.mp4";

const ForProfile = (props) => {
    const navigate = useNavigate();
    const main = useRef();
    const [talking, setTalking] = useState(false);
    const [showForm, setShowForm] = useState(false);

    const profile = {
        alias: "Messi",
        fullname: "Lionel Messi",
        video: 'https://svc.staging.squadz.live/download/O9MEZ9778209c/O9MEZ9ab2c7d2/O9MLWMdc33874/messi-vid-3.mp4',
        useVoice: "fromId",
        voiceId:"O9MLXW77ba3c3",
    };

    useGSAP(
        () => {
            gsap.fromTo(
                ".m-header",
                { y: -50, opacity: 0, skewY: 1 },
                {
                    y: 0,
                    opacity: 1,
                    skewY: 0,
                }
            );

            timelineHero();
            timelineFaq();
            timelineTrending();
            // timelineCreate();

            animatePhone({ talking, setTalking, callouts });
            animateMembers();

            animateConnect();

            // const typingtl = gsap.timeline({
            //     scrollTrigger: {
            //         trigger: ".m-section-connect .m-section-title",
            //         start: "50% 10%",
            //         end: "+=200",
            //         scrub: 1,
            //     },
            // });
        },
        { scope: main }
    );

    const lenis = useLenis(({ velocity }) => {
        // gsap.to(".m-header", {
        //     skewY: velocity / 20,
        //     y: -Math.abs(velocity * 2),
        // });
        // gsap.to(".a-text", {
        //     skewY: velocity / 10,
        //     y: velocity * 4,
        // });
    });

    const persons = [
        { img: person1, name: "Alice Johnson", msg: "Hello, Rex! It's a pleasure to see you today." },
        { img: person2, name: "Diana Smith", msg: "Hi there, Bob! I hope you're having a fantastic day." },
        { img: person3, name: "Charlie Williams", msg: "Greetings, Jane! Wishing you a wonderful and productive day ahead." },

        { img: person4, name: "Eve Davis", msg: "Nice to see you, Peter! I trust you're enjoying the moment." },

        { img: person5, name: "Bob Anderson", msg: "Hey, Diana! Your presence brightens up the room. Welcome!" },
        { img: person6, name: "Grace Taylor", msg: "Salutations, Quinn! May your day be filled with joy and success." },
        { img: person7, name: "Frank Miller", msg: "Howdy, Greg! Your positive energy is contagious. Keep shining!" },

        { img: person8, name: "Shiela Miller", msg: "Hello, Henry! It's a pleasure to have you here. Let's make it a great day!" },
        { img: person9, name: "Ivy Wilson", msg: "Hey, Joshua! Your presence adds so much warmth. Welcome to our gathering." },
        { img: person10, name: "Jack Robinson", msg: "Hi, Carl! Wishing you a day filled with exciting opportunities and happiness." },
    ];

    const callouts = [
        {
            id: "001",
            text: `Oh my God, I can't believe I'm actually talking to you! You're Lionel Messi!`,
            thumb: person5,
            price:'$3.00'
        },
        {
            id: "002",
            text: `Thank you! It's always nice to meet fans. How are you? How's everything going? I know it can be a bit overwhelming meeting someone, but trust me, I'm just like anyone else. `,
            thumb: messiSmall,
        },
        {
            id: "003",
            text: `I'm shaking! You're the reason I fell in love with football. I've watched almost every game you've played!`,
            thumb: person5,
            price:'$3.00'
        },
    ];

    const [post, setPost] = useState({
        index: "11",
        name: "Lionell Messi",
        nametag: "@lmessi",
        thumb: messiSmall,
        video: messiVid3,
        msg: "Hello, Rex! It's a pleasure to see you today.",
        gradient: "from-indigo-500 to-rose-500",
      
    });

    const posts = [
        {
            index: "0",
            name: "Lionell Messi",
            nametag: "@lmessi",
            thumb: messiSmall,
            video: messiVid1,
            msg: "Hello, Rex! It's a pleasure to see you today.",
           
            gradient: "from-cyan-500 to-blue-500",
        },

        {
            index: "1",
            name: "Lionel Messi",
            nametag: "@lmessi",
            thumb: messiSmall,
            video: messiVid2,
            msg: "Hello, Rex! It's a pleasure to see you today.",
            gradient: "from-rose-500 to-amber-500",
        },

        {
            index: "2",
            name: "Lionel Messi",
            nametag: "@lmessi",
            thumb: messiSmall,
            video: messiVid3,
            msg: "Hello, Rex! It's a pleasure to see you today.",
            gradient: "from-indigo-500 to-rose-500",
        },
    ];

    useEffect(() => {
     
        if (talking === "respond") {
         //   post.video = messiVidTalk;
            post.talk = true
            setPost({ ...post });
        } else {
            delete  post.talk
         //   post.video = messiVid3;
            setPost({ ...post });
        }
    }, [talking]);

    return (
        <ReactLenis root>
            {showForm && (
                <Popup
                    fixed={true}
                    OnHide={() => {
                        setShowForm(false);
                    }}
                >
                    <FormTalk
                        profile={profile}
                        OnClose={() => {
                            setShowForm(false);
                        }}
                    />
                </Popup>
            )}
            <div className="md:hidden">
                <Menu market={true} />
            </div>
            <div className="pgm mx-auto flex flex-col" ref={main}>
                <div className="bg-floating opacity-40">
                    <div className="bg-violet"></div>
                    <div className="bg-torq"></div>
                </div>
                <div className="m-header">
                    <div className=" container  mx-auto flex items-center md:mt-5 p-3 px-0 rounded-2xl overflow-hidden">
                        <div className="flex flex-row items-center flex-1">
                            <div
                                className="logo ml-0 md:ml-5 md:pl-0 flex-1 !text-3xl"
                                onClick={() => {
                                    navigate("/");
                                }}
                            >
                                fanlynk
                            </div>
                        </div>

                        <div
                            className="ps-3  text-lg font-alt-bold"
                            onClick={() => {
                                setShowForm(true);
                            }}
                        >
                            <button className="button">Talk to {profile.alias}</button>
                        </div>

                        {/* <Header menu={`hide`} /> */}
                    </div>
                </div>

                {/* ---------------------------------------- PHONE     */}

                <div className="phone-container fixed left-0 top-1/3 md:top-1/4 w-full h-full flex items-center justify-center z-40">
                    {/* PHONE */}
                    <div className="phone h-800 w-400 rounded-5xl  bg-white shadow-2xl shadow-indigo-500/20">
                        <div className="phone-screen p-6 gap-5 flex flex-col">
                            {posts.map((post, p) => (
                                <Card key={p} data={post} />
                            ))}
                        </div>
                    </div>

                    <div className="card-post w-400 absolute z-30 flex flex-col gap-3 px-5" style={{ paddingBottom: 60 }}>
                        <Card data={post} />

                        <div className="message-box flex flex-col gap-3 z-40 absolute left-0 bottom-0 w-full z-10 px-5">
                            <div className="bg-white/90 rounded-2xl  py-3 px-3 flex items-center gap-3 ">
                                <div className="message flex-1 pl-2">Say something nice...</div>
                                <div className="bg-green-400 rounded-full w-6 h-6 flex items-center justify-center">
                                    <FaArrowUp className="text-white" />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="h-800 min-w-400 absolute z-30">
                        <div className="mem-vid-1 overflow-hidden absolute left-0 top-0">
                            <Card data={posts[0]} locked={false} />
                        </div>
                        <div className="mem-vid-2  overflow-hidden  absolute left-0 top-0">
                            <Card data={posts[1]} locked={false} />
                           
                        </div>
                        <div className="mem-vid-3  overflow-hidden  absolute left-0 top-0">
                            <Card data={posts[2]} locked={false} />
                        </div>
                    </div> */}

                    {/* CALLOUTS */}
                    <div className="h-800 min-w-500 absolute z-40 hidden md:block">
                        <div className="callout-1 absolute -left-1/2 top-40 bg-white/80 p-6 rounded-3xl max-w-350 flex flex-row gap-5">
                            <Callout data={callouts[0]} />
                        </div>

                        <div className="callout-2 absolute -right-1/2 top-1/2 -translate-y-1/2 bg-white p-6 rounded-3xl  max-w-350 flex flex-row gap-5">
                            <Callout data={callouts[1]} />
                        </div>

                        <div className="callout-3 absolute -left-1/2 bottom-1/4 bg-white p-6 rounded-3xl  max-w-350 flex flex-row gap-5">
                            <Callout data={callouts[2]} />
                        </div>
                    </div>
                </div>
                {/* ---------------------------------------- SECTION HERO */}
                <div className="m-section m-section-hero container mx-auto text-center items-center w-full mb-0 !max-h-300">
                    <div className="relative h-700 w-full">
                        <div className="absolute left-0 top-0 overflow-hidden rounded-5xl h-full w-full z-10">
                            <img src={messiHero} className="object-cover w-full h-full" />
                        </div>

                        <div className="absolute bottom-0 left-0 p-10 z-20 w-full flex-1 flex flex-row items-center justify-between">
                            <div className="flex flex-row gap-4">
                                <div className="font-alt-extrabold text-7xl z-20 max-w-60 text-left">{profile.fullname} </div>
                                <FaCheck className="text-xl badge w-8 h-8 flex items-center justify-center bg-white rounded-full p-2" />
                            </div>
                            <div className="hidden md:flex  w-40 h-40 items-center justify-center flex p-4 cursor-pointer relative font-alt-extrabold cursor-pointer text-xl">
                                <div className="w-full h-full border-2 border-dashed border-black rounded-full absolute left-0 top-0  cursor-pointer hover:bg-black/10" onClick={()=>{
                                    setShowForm(true)
                                }}></div>
                                Talk to {profile.alias} 
                            </div>
                        </div>
                    </div>
                </div>

                {/* ---------------------------------------- SECTION MEMBERSHIP */}
                <div className="m-section m-section-membership container mx-auto text-center flex flex-col items-center justify-center mb-0 !min-h-auto py-40">
                    <div className="flex flex-col flex-1">
                        <div className="flex flex-col flex-1 justify-center m-section-title p-5">
                            <div className="a-text font-alt-extrabold text-5xl leading-tight md:text-7xl md:leading-tight ">
                                <span className="rounded-3xl px-5 mx-4" style={{ background: "#FFB3CA" }}>
                                    Monthly
                                </span><br/>
                                Memberships.
                            </div>

                            <div className="a-text font-alt-extrabold text-5xl leading-tight md:text-7xl md:leading-tight">
                                for your biggest
                                <span className="rounded-3xl px-5 mx-4" style={{ background: "#C4A8FF" }}>
                                    fans.
                                </span>
                            </div>

                            <div className="a-text opacity-40 my-10 text-base font-alt-semibold p-5">
                                <div>Earn a recurring income by accepting monthly subscriptions for exclusive</div>
                                <div>behind-the-scenes content that your fans will love.</div>
                            </div>
                        </div>

                        <div className="graphic flex items-center justify-center" style={{ height: 7500 }}></div>
                    </div>
                </div>

                {/* ---------------------------------------- SECTION CONNECT */}
                <div className="m-section m-section-connect container mx-auto text-center flex flex-col items-center justify-center mb-0">
                    <div className="flex flex-col flex-1">
                        <div className="flex flex-col flex-1 justify-center font-alt-extrabold text-5xl leading-tight md:text-7xl md:leading-tight m-section-title">
                            <div className="a-text">
                                Connect with your
                                <span className="rounded-3xl px-5 mx-4" style={{ background: "#FFB3CA" }}>
                                    fans.
                                </span>
                            </div>

                            <div className="a-text">
                                and get<br/>
                                <span className="rounded-3xl px-5 mx-3" style={{ background: "#62E1BB" }}>paid
                                </span>
                                doing it
                            </div>

                            <div className="opacity-40 my-10 text-base font-alt-semibold p-5">
                                <div>Earn a recurring income by accepting monthly subscriptions for exclusive</div>
                                <div>behind-the-scenes content that your fans will love.</div>
                            </div>
                        </div>

                        <div className="graphic flex items-center justify-center" style={{ height: 3000 }}></div>
                    </div>
                </div>

                {/* ---------------------------------------- SECTION FAQ */}
                <div className="m-section m-section-faq container mx-auto flex flex-col justify-center mb-0 py-40">
                    <div className="flex  flex-col md:flex-row   gap-5 md:gap-20 items-center gcontent">
                        <div className="flex-1 relative">
                            <div className="a-text font-alt-extrabold text-5xl leading-tight md:text-7xl md:leading-tight text-center md:text-left ">
                                Frequently
                                <div>asked</div>
                                <span className="rounded-3xl px-4" style={{ background: "#FFC28A" }}>
                                    questions.
                                </span>
                            </div>
                        </div>

                        <div className="flex-1 flex relative items-center p-5">
                            <div className="accordion">
                                <div className="item item-open text-base">
                                    <div className="label">
                                        <div className="flex-1 font-alt-extrabold text-lg">How many followers do I need to become a fanlynk Creator? </div>
                                        <img src={arrowDown} className="arrow" />
                                    </div>
                                    <div className="value">You need at least 10,000 followers across all your social media platforms to apply.</div>
                                </div>

                                <div className="item item text-base">
                                    <div className="label">
                                        <div className="flex-1 font-alt-extrabold text-lg">How many followers do I need to become a fanlynk Creator? </div>
                                        <img src={arrowDown} className="arrow" />
                                    </div>
                                    <div className="value">You need at least 10,000 followers across all your social media platforms to apply.</div>
                                </div>

                                <div className="item item text-base">
                                    <div className="label">
                                        <div className="flex-1 font-alt-extrabold text-lg">How many followers do I need to become a fanlynk Creator? </div>
                                        <img src={arrowDown} className="arrow" />
                                    </div>
                                    <div className="value">You need at least 10,000 followers across all your social media platforms to apply.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ---------------------------------------- SECTION TRENDING  */}
                <div className="m-dark flex-col  justify-center mb-0 pt-20 pb-10">
                    <div className="bg-floating">
                        <div className="bg-violet"></div>
                        <div className="bg-torq"></div>
                        <div className="bg-grid"></div>
                    </div>
                    <div className="m-section m-section-trending container mx-auto flex flex-col justify-evenly  mb-0 !min-h-800">
                        <div className="flex-col md:flex-row  gap-5 md:gap-10 pb-30">
                            <div className="a-text font-alt-extrabold leading-tight flex-1 text-5xl leading-tight md:text-7xl md:leading-tight text-center md:text-left ">
                                Trending creator /
                                <div>
                                    influencer{" "}
                                    <span className="rounded-3xl px-4" style={{ background: "#766BF4" }}>
                                        models.
                                    </span>
                                </div>
                            </div>
                            <div className="flex pt-10 flex-row gap-6 items-center p-2">
                                <a
                                    href="/join-creators"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/join-creators");
                                    }}
                                    className="button button-inline text-lg font-alt-bold px-10 button-white"
                                >
                                    Start for free
                                </a>
                                <a href="/avatars" className="link text-lg font-alt-bold link-white">
                                    Browse avatars
                                </a>
                            </div>
                        </div>

                        <div
                            className="slider scale-90 md:scale-100"
                            onClick={() => {
                                navigate("/avatars");
                            }}
                        >
                            <div className="slider-track">
                                {persons.map((person, p) => (
                                    <div key={p} className="item">
                                        <img src={person.img} className="s-img" />
                                        <div className="chat">
                                            <div className="text-xs opacity-50 mb-2">{person.name}</div>
                                            <div className="mb-3">{person.msg}</div>
                                            <div className="flex gap-2">
                                                <Iicon icon={"video-white"} />
                                                <Iicon icon={"speaker"} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {persons.map((person, p) => (
                                    <div key={p} className="item">
                                        <img src={person.img} className="s-img" />
                                        <div className="chat">
                                            <div className="text-xs opacity-50 mb-2">{person.name}</div>
                                            <div className="mb-3">{person.msg}</div>
                                            <div className="flex gap-2">
                                                <Iicon icon={"video-white"} />
                                                <Iicon icon={"speaker"} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                {/* ---------------------------------------- SECTION CREATE */}
                {/* <div className="m-section-create flex flex-col justify-center  mb-0 pt-40">
                    <div className="flex gap-20 container  mx-auto flex-1 pb-40">
                        <div className="flex-1 flex flex-col justify-center">
                            <div className="a-text font-alt-extrabold text-5xl leading-tight md:text-7xl md:leading-tight text-center md:text-left ">
                                What will
                                <div>
                                    you
                                    <span className="rounded-3xl px-4 ml-2" style={{ background: "#9D94FF" }}>
                                        create.
                                    </span>
                                </div>
                            </div>
                            <div className="opacity-40 my-10 text-base font-alt-semibold md:pr-10 p-5 md:p-0 text-center md:text-left">
                                Build the AI version of you to scale your expertise and availability, infinitely.
                            </div>
                            <div className="flex pt-20 flex-row gap-6 items-center p-2">
                                <a
                                    href="/join-creators"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/join-creators");
                                    }}
                                    className="button button-inline text-lg font-alt-bold px-10"
                                >
                                    Start for free
                                </a>
                                <a href="/avatars" className="link text-lg font-alt-bold link-black">
                                    Browse avatars
                                </a>
                            </div>
                        </div>

                        <div className="flex-1 flex-col relative  justify-center hidden md:flex">
                            <div className="relative h-3/6">
                                <div className="box box1  overflow-hidden">
                                    <img src={person16} className="img-cover" />
                                </div>
                                <div className="box box2  overflow-hidden">
                                    <img src={person15} className="img-cover" />
                                </div>

                                <div className="box box3  overflow-hidden">
                                    <img src={person12} className="img-cover" />
                                </div>

                                <img src={graphicChat} className="graphic1" />
                                <img src={graphicThumb} className="graphic2" />
                                <img src={graphicPic} className="graphic3" />
                            </div>
                        </div>
                    </div>

                    <MarketingFooter />
                </div> */}

                <div className="pt-10">
                <MarketingFooter />
                </div>
            </div>
        </ReactLenis>
    );
};

export default ForProfile;
