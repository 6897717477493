import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";

gsap.registerPlugin(TextPlugin);

export const animatePhone = ({ talking, setTalking, callouts }) => {
    // PHONE
    const phonetl_in = gsap.timeline({
        scrollTrigger: {
            trigger: ".m-section-membership",
            start: "top 0%",
            end: "+=300",
            scrub: 1,
        },
    });
    phonetl_in.from(".phone-container", { opacity: 0, yPercent: 60 });

    const phonetl_in1 = gsap.timeline({
        scrollTrigger: {
            trigger: ".m-section-membership .m-section-title",
            start: "bottom 10%",
            end: "+=300",
            scrub: 1,
        },
    });
    phonetl_in1.to(".phone-container", { yPercent: -25 });

    const phonetl_out = gsap.timeline({
        scrollTrigger: {
            trigger: ".m-section-connect",
            start: "top bottom",
            end: "+=200",
            scrub: 1,
        },
    });

    phonetl_out.to(".phone", { opacity: 0 }, "<");

    // const card_in = gsap.timeline({
    //     scrollTrigger: {
    //         trigger: ".m-section-connect .m-section-title",
    //         start: "top bottom",
    //         end: "bottom top",
    //         scrub: 1,
    //     },
    // });
    // card_in.to(".card-post", { yPercent: 90 });

    const card_center = gsap.timeline({
        scrollTrigger: {
            trigger: ".m-section-connect",
            start: "top 0%",
            end: "96% bottom",
            scrub: 1,
        },
    });

    card_center.from(".card-post", { duration: 10, yPercent: 50, opacity: 0 }, ">");

    card_center.to(".card-post", { duration: 10, yPercent: 0, scale: window.innerWidth <= 640 ? 1 : 1.3 }, ">");
    card_center.to(".card-post .locked", { opacity: 0 });
    card_center.to(".card-post .info-bot", { opacity: 0, y: 40 });

    card_center.from(".card-post .message-box", { opacity: 0, y: 40 });

    const text = callouts[0].text;
    const chars = text.split("");
    chars.forEach((char, i) => {
        card_center.to(
            ".card-post .message-box .message",
            {
                text: text.slice(0, i + 1),
                duration: 0.5,
                ease: "none",
            },
            "-=0.05"
        );
    });

    card_center.call(() => {
        setTalking(null);
    });

    card_center.from(".callout-1", { duration: 10, yPercent: 25, opacity: 0, delay: 0.5 });
    card_center.to(".card-post .message-box .message", {
        text: "Say something nice...",
        duration: 0,
    });

    card_center.call(() => {
        setTalking("respond");
    });
    card_center.from(".callout-2", { duration: 10, yPercent: 25, opacity: 0, delay: 2 });

    //NEED CODE TO WAIT FOR VIDEO RESPONSE TO FINISH

    const text2 = callouts[2].text;
    const chars2 = text.split("");
    chars2.forEach((char, i) => {
        card_center.to(
            ".card-post .message-box .message",
            {
                text: text2.slice(0, i + 1),
                duration: 0.5,
                ease: "none",
            },
            "-=0.05"
        );
    });
    card_center.from(".callout-3", { duration: 10, yPercent: 25, opacity: 0, delay: 0.5 });
    card_center.to(".card-post .message-box .message", {
        text: "Say something nice...",
        duration: 0,
    });

    card_center.to(".phone-container", {
        opacity: 0,
        scale: 0.5,
        delay: 2,
    });
};
