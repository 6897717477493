import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export const animateMembers= () => {
     // MEMBERSHIP
     const memtl_title = gsap.timeline({
        scrollTrigger: {
            trigger: ".m-section-membership",
            start: "top 30%",
            end: "+=100",
            scrub: 1,
        },
    });
    
    memtl_title.from(".m-section-membership .a-text", { opacity: 0, y:50 });


     const memtl_in = gsap.timeline({
        scrollTrigger: {
            trigger: ".m-section-membership .graphic",
            start: "top 10%",
            end: "bottom top",
            scrub: 1,
        },
    });
   

    memtl_in.from(".card-0", { duration: 10, scale:1.5, opacity:0});
    memtl_in.from(".card-0 .locked", { duration: 10, opacity:0 });
    memtl_in.to(".phone-screen", { duration: 10, yPercent:-20 });
    
    memtl_in.from(".card-1", { duration: 10,scale:1.5, opacity:0});
    memtl_in.from(".card-1 .locked", { duration: 10, opacity:0 });

    memtl_in.to(".phone-screen", { duration: 10,  yPercent:-50 });

    memtl_in.from(".card-2", { duration: 10, scale:1.5, opacity:0});
    memtl_in.from(".card-2 .locked", { duration: 10, opacity:0 });

    // memtl_in.from(".mem-vid-1", { opacity: 0 });
    // memtl_in.from(".mem-vid-1", { duration: 10, xPercent: -120, yPercent: -20 });
    // memtl_in.from(".mem-vid-2", { opacity: 0 });
    // memtl_in.from(".mem-vid-2", { duration: 10, xPercent: 120, yPercent: -20 });
    // memtl_in.from(".mem-vid-3", { opacity: 0 });
    // memtl_in.from(".mem-vid-3", { duration: 10, xPercent: -120, yPercent: 0 });

    // const memtl_out = gsap.timeline({
    //     scrollTrigger: {
    //         trigger: ".m-section-membership .graphic",
    //         start: "top 10%",
    //         end: "50% top",
    //         scrub: 1,
    //     },
    // });
    // memtl_out.to(".mem-vid-1", { opacity: 0 });
    // memtl_out.to(".mem-vid-2", { opacity: 0 });
    // memtl_out.to(".mem-vid-3", { opacity: 0 });
};
