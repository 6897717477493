import { useEffect, useState } from "react";
import { weatherVideo } from "src/apis/utils";
import FormPhone from "src/components/form/phone";
import messiVidSubmit from "src/assets/vids/messi-vid-submit.mp4";
import girl1 from "src/assets/vids/girl1.mp4";
import Preloader from "src/components/preloader/preloader";

const FormTalk = (props) => {
    const { profile } = props;
    const [fetching, setFetching] = useState(false);
    const [formState, setFormState] = useState(null);

    const [formData, setFormData] = useState({
        name: "",
        // phonenumber: "",
        email: "",
        video: profile.video,
        useVoice: profile.useVoice,
        voiceId: profile.voiceId,

        // useVoice: "default",
        // voiceGender: "male",

        latitude: null,
        longitude: null,
    });

    useEffect(() => {
        setFetching(true);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition, showError);
        } else {
            console.log("Geolocation is not supported by this browser.");
            setFetching(false);
        }
    }, []);

    useEffect(() => {
        console.log(formData);
        if (formData.longitude && formData.latitude) {
            setFetching(false);
        }
    }, [formData]);

    const showPosition = (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        console.log("Latitude: " + latitude + ", Longitude: " + longitude);
        formData.latitude = latitude.toString();
        formData.longitude = longitude.toString();
        setFormData({ ...formData });
    };

    const showError = (error) => {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                console.log("User denied the request for Geolocation.");
                break;
            case error.POSITION_UNAVAILABLE:
                console.log("Location information is unavailable.");
                break;
            case error.TIMEOUT:
                console.log("The request to get user location timed out.");
                break;
            case error.UNKNOWN_ERROR:
                console.log("An unknown error occurred.");
                break;
        }
    };

    const handleSubmit = async () => {
        try {
            const res = await weatherVideo(formData);
            console.log(res);
            setFormState("success");
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            {fetching && (
                <div className="bg-white/60 rounded-3xl absolute left-0 top-0 w-full h-full z-20">
                    <Preloader />
                </div>
            )}
            <div className="flex flex-col bg-white p-10 rounded-3xl">
                <div className="flex flex-col gap-6">
                    {!formState && (
                        <>
                            <div className="text-3xl  font-alt-extrabold text-center">Talk to {profile.alias}</div>
                            <div className="flex flex-col gap-3">
                                <div className="">Your Name:</div>
                                <div className="form-item-input flex border !border-zinc-300 rounded-3xl relative overflow-hidden">
                                    <input
                                        type="text"
                                        className="bg-white "
                                        placeholder="Add your name"
                                        value={formData.name}
                                        onChange={(e) => {
                                            formData.name = e.target.value;
                                            setFormData({ ...formData });
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col gap-3">
                                <div className="">Your Email:</div>
                                <div className="form-item-input flex border !border-zinc-300 rounded-3xl relative overflow-hidden">
                                    <input
                                        type="text"
                                        className="bg-white "
                                        placeholder="Add your email"
                                        value={formData.email}
                                        onChange={(e) => {
                                            formData.email = e.target.value;
                                            setFormData({ ...formData });
                                        }}
                                    />
                                </div>
                            </div>
                            {/* <div className="flex flex-col gap-3">
                            <div className="">Your Phone:</div>
                            <FormPhone
                                OnChange={(phone) => {
                                    formData.phonenumber = phone;
                                    setFormData({ ...formData });
                                    // setNumber(phone);
                                }}
                            />
                        </div> */}

                            <div className="pt-4">
                                <button
                                    disabled={formData.name === "" || formData.phonenumber === ""}
                                    className="button"
                                    onClick={async () => {
                                        await handleSubmit();
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                        </>
                    )}

                    {formState === "success" && (
                        <>
                            <div className="text-5xl font-alt-extrabold text-center">Thank your for submitting</div>

                            <div className="min-h-400 max-h-400 relative rounded-2xl overflow-hidden">
                                <video src={messiVidSubmit} playsInline autoPlay muted className="object-cover w-full h-full loop" />
                            </div>

                            <div
                                className="cursor-pointer  text-center text-base opacity-40 hover:opacity-100"
                                onClick={() => {
                                    props.OnClose(true);
                                }}
                            >
                                Close
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default FormTalk;
