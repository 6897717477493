import { useEffect, useRef, useState } from "react";
import "./form.css";
import axios from "axios";
import endpoint from "src/apis/endpoints";
import { getStoredUser } from "../auth/auth";
import Button from "../ui/button";
import { imageMaker, upload } from "src/apis/utils";

const FormInput = (props) => {
    const label = props.label ? props.label : null;
    const type = props.type ? props.type : "text";
    const placeholder = props.placeholder;
    let uploadInputRef = useRef();

    const [isChecked, setIsChecked] = useState(false);
    const [theFile, setTheFile] = useState(null);

    useEffect(() => {
        if (type === "slider") {
            let thevalue = props.value;
            setIsChecked(thevalue);
        }
    }, [type, props.value]);

    const FileUpload = (props) => {
        const handleOnChange = (file) => {
          
            let fileReader;
            const handleFileRead = (e) => {
                let filecontent = fileReader.result;
                // filecontent = filecontent.split("base64,")[1];

                if (props.OnSelect) {
                    props.OnSelect(file);
                }
                // if(props.autoUpload){
                //     handleUpload()
                // }
            };

            fileReader = new FileReader();
            fileReader.onloadend = handleFileRead;
            fileReader.readAsDataURL(file);
        };

        const handleUpload = async () => {
            if (props.OnUpload) {
                props.OnUpload(theFile);
            }

            let res = await upload({ file: theFile, channelid: props.segment, networkid: props.directory });

            console.log("upload res : ", res);

            if (res && res.data[0] && props.OnSuccess) {
                setTheFile(null);

                let theresponse = res.data[0];

                if (theFile.type.indexOf("image") !== -1) {
                    let fullpath = `${theresponse.filepaths3}`;
                    console.log("path to use on imageMaker :", fullpath);
                    const uploadRes = await imageMaker(fullpath);
                    console.log("uploadRes :", uploadRes);

                    theresponse = { ...theresponse, ...uploadRes };
                }
                props.OnSuccess(theresponse);
            }
        };

        return (
            <>
                {/* {theFile && <div className="flex-1 px-3">{theFile.name}</div>} */}

                <label>
                    <input
                        ref={uploadInputRef}
                        type="file"
                        accept={props.accept ? props.accept : ""}
                        className="input-file"
                        onChange={(e) => {
                        
                                
                            const theSelectedFile = e.target.files[0];
                            setTheFile(theSelectedFile);
                            handleOnChange(theSelectedFile);
                            
                        }}
                    />
                    {props.value && props.value !== "" && !props.value.name && !props.labelHasSelected &&(
                        <span className="flex justify-content-center items-center">
                            <span className="flex-1">... {props.value.slice(-20)} </span>
                            {/* <span className="button button-small flex-0">change</span> */}
                        </span>
                    )}

                    {props.value && props.value !== ""  && props.labelHasSelected &&(
                        <span className="flex justify-content-center items-center">
                            <span className="flex-1">{props.labelHasSelected} </span>
                            {/* <span className="button button-small flex-0">change</span> */}
                        </span>
                    )}

                    {theFile && !props.labelHasSelected &&(
                        <span className="flex justify-content-center items-center">
                            <span className="flex-1 me-2">{theFile.name} </span>
                            {/* <span className="button button-small flex-0">change</span> */}
                        </span>
                    )}

                    {!theFile && props.value === "" && props.label}
                </label>

                {props.OnClear && (props.value !== "" || theFile) && !props.labelHasSelected && (
                    <span
                        className="button button-small button-trans flex-0"
                        onClick={() => {
                            props.OnClear(true);
                            if (uploadInputRef && uploadInputRef.current) {
                                uploadInputRef.current.value = "";
                                setTheFile(null);
                            }
                        }}
                    >
                        clear
                    </span>
                )}

                {theFile && props.requiredToUpload === undefined && !props.labelHasSelected &&(
                    <div
                        className="button button-small button-white flex-0 ms-2"
                        onClick={() => {
                            handleUpload();
                        }}
                    >
                        Upload
                    </div>
                )}

                {theFile && props.requiredToUpload && props.requiredToUpload !== "" && !props.labelHasSelected &&(
                    <div
                        className="button button-small button-white flex-0"
                        onClick={() => {
                            handleUpload();
                        }}
                    >
                        Upload
                    </div>
                )}
            </>
        );
    };

    const handleUrlUpload = async (url) => {
        const uploadRes = await imageMaker(url);
        return uploadRes;
    };

    return (
        <>
            {props.iicon && type === "file" && (
                <i className={`iicon ${props.iicon} link link-icon`}>
                    <FileUpload {...props} OnSuccess={props.OnSuccess} OnFile={props.OnFile} />
                </i>
            )}

            {!props.iicon && (
                <div className={`form-item flex flex-col form-item-${type} ${props.className ? props.className : ""}`}>
                    {label && type !== "file" && (
                        <div className="form-item-label">
                            <div>{props.label}</div>
                            {props.required && <span className="required-icon">*</span>}
                        </div>
                    )}

                    {type === "text" && (
                        <div className="form-item-input">
                            <input type={type} placeholder={placeholder} value={props.value} onChange={props.onChange} />
                        </div>
                    )}

                    {type === "textarea" && (
                        <div className="form-item-input flex flex-1 ">
                            <textarea placeholder={placeholder} value={props.value} onChange={props.onChange} rows={10}></textarea>
                        </div>
                    )}

                    {type === "select" && (
                        <div className="form-item-input form-item-select">
                            <select value={props.value} onChange={props.onChange}>
                                {placeholder && (
                                    <option value="" className="opacity-25">
                                        {placeholder}
                                    </option>
                                )}
                                {props.options &&
                                    props.options.map((option, o) => (
                                        <option
                                            key={o}
                                            data-id={option.id}
                                            value={props.valueSelector ? option[props.valueSelector] : option.id}
                                            data-path={option.path}
                                            data-filepaths3={option.filepaths3}
                                            data-cache={option.cache}
                                            data-modelid={option.modelid}
                                            data-description={option.description}
                                            data-prompt={option.prompt}
                                        >
                                            {option.name}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    )}

                    {type === "file" && !props.iicon && (
                        <div className={`form-item-input ${props.className ? props.className : ""}`}>
                            <FileUpload {...props} OnSuccess={props.OnSuccess} OnFile={props.OnFile} />
                        </div>
                    )}

                    {type === "upload-url" && (
                        <div className="form-item-input flex-row flex">
                            <input className="flex-1" type={type} placeholder={placeholder} value={props.value} onChange={props.onChange} />
                            <Button
                                className={"flex-0"}
                                disabled={!props.value || props.value === ""}
                                onClick={async () => {
                                    if (props.OnUpload) {
                                        props.OnUpload(true);
                                    }

                                    if (props.value && props.value !== "") {
                                        let resimage = await handleUrlUpload(props.value);
                                        if (props.OnSuccess) {
                                            props.OnSuccess(resimage);
                                        }
                                    }
                                }}
                            >
                                Upload
                            </Button>
                        </div>
                    )}

                    {type === "slider" && (
                        <div className="knob-switch">
                            <div className="toggler">
                                <input
                                    type="checkbox"
                                    className="checkbox"
                                    checked={isChecked}
                                    value={isChecked}
                                    onChange={() => {
                                        setIsChecked(!isChecked);
                                        props.onChange(!isChecked);
                                    }}
                                />
                                <label
                                    className="label"
                                    onClick={() => {
                                        setIsChecked(!isChecked);
                                        props.onChange(!isChecked);
                                    }}
                                >
                                    <div className={`knob ${isChecked ? "active" : ""}`}></div>
                                </label>
                            </div>
                        </div>
                    )}

                    {props.note && <div className="small opacity-50 mt-2">{props.note}</div>}
                </div>
            )}
        </>
    );
};

export default FormInput;
