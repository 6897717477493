import { useEffect, useRef } from "react";
import { FaCheck } from "react-icons/fa";
import { LuLock } from "react-icons/lu";
import messiVidTalk from "src/assets/vids/messi-vid-talk.mp4";

const Card = (props) => {
    const { index, video, thumb, name, nametag, msg, gradient, mute, talk } = props.data;
    let vidRef = useRef();

    useEffect(() => {
        // if(mute){
        //     vidRef.current.muted = true;
        // }else{
        //     vidRef.current.muted = false;
        // }
    }, [mute]);

    return (
        <div className={`card card-${index} w-full min-h-500 max-h-500 bg-black relative overflow-hidden rounded-3xl`}>
            {props.locked !== false && (
                <>
                    <div
                        className={`locked blur-bg absolute left-0 top-0 w-full h-full z-20 opacity-40 bg-gradient-to-tr ${
                            gradient ? gradient : "from-indigo-500 to-rose-500"
                        } `}
                    ></div>
                    <div className="locked  blur absolute w-full h-full left-0 top-0 flex backdrop-blur-lg z-20  rounded-3xl"></div>

                    <div className="locked blur-content absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center  z-30 gap-4 ">
                        <div className="bg-white/40 rounded-full w-20 h-20 flex items-center justify-center">
                            <LuLock className="text-4xl text-white" />
                        </div>
                        <div className="text-white text-xl">Subscribe to unlock</div>
                        <div>
                            <button className="button text-xs !bg-white !text-black py-2 px-2 pl-4">Talk to {name}</button>
                        </div>
                    </div>
                </>
            )}
            <div className="video relative z-10">
                {!talk ? (
                    <video src={video} ref={vidRef} muted playsInline loop autoPlay  />
                ) : (
                    <video src={messiVidTalk} playsInline autoPlay />
                )}
            </div>

            <div className="info-top absolute top-0 left-0 w-full z-20">
                <div className="p-4  flex flex-row gap-4 ">
                    <div className="flex flex-1 flex-row gap-4 items-center">
                        <div className="thumb rounded-full w-10 h-10 relative  overflow-hidden">
                            <img src={thumb} className="object-cover w-full h-full" />
                        </div>
                        <div className="info flex flex-col">
                            <div className="name flex flex-row items-center gap-3">
                                <div className="text-white">{name}</div>

                                <FaCheck className="text-xs badge w-4 h-4 flex items-center justify-center bg-white rounded-full p-1" />
                            </div>
                            <div className="nametag text-xs text-white">{nametag}</div>
                        </div>
                    </div>

                    <div className="text-xs text-white opacity-40">2 hrs ago</div>
                </div>
            </div>

            <div className="info-bot absolute bottom-0 left-0 w-full z-20">
                <div className="p-4 flex flex-col gap-2 ">
                    <div className="text-white">{msg}</div>
                    <div className="flex items-start justify-start text-xs">
                        <div className="bg-white rounded-full py-1 px-3">Exclusive</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
