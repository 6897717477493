

import { TbCoin } from "react-icons/tb";

const Callout = (props) => {
    const {data} = props
    return (
        <>
            <div className="thumb rounded-full min-w-10 max-w-10 min-h-10  max-h-10 relative  overflow-hidden">
                <img src={data.thumb} className="object-cover w-full h-full" />
            </div>
            <div className="flex flex-col gap-4">
                {data.text}
                {data.price &&
                <div className="flex flex-row gap-1 items-center text-center text-xs font-bold">
                  
                    <TbCoin className="text-lg" />
                    {data.price}
                </div>
}
            </div>
        </>
    );
};

export default Callout;
