import { logout } from "src/components/auth/auth";

export const handleResponse = (query, res) => {
    //  console.log(`-> handling (${query?.Params?.act}) ::` , res)
    const sys = query?.Params?.sys;
    const act = query?.Params?.act;
    const pagesize = query?.Params?.pagesize;

    let response = res;
    let expectedres = "result";
    if (sys === "page") {
        expectedres = act;
    }

    if (res.data) {
        if (res.data[0] && res.data[0].error === "Auth Failed") {
            logout();
            window.location.href = "/";
        } else if (res.data.result && res.data.result[0] && res.data.result[0].error) {
            // error
            response = res.data.result[0].error;
            console.log("error response : ", response);

            if (response === "Auth Failed") {
                logout();
                window.location.href = "/";
            }
        } else if (res.data[expectedres] === null) {
            response = [];
        } else if (res.data[expectedres]) {
            // success
            if (pagesize === "1") {
                response = res.data[expectedres][0];
            } else {
                if ((response = res.data[expectedres] === null)) {
                    response = [];
                } else {
                    response = res.data[expectedres];
                }
            }
        } else {
            console.log("unmarshalled:", response);
            response = res.data;
        }
    }

    //console.log(`<- returning (${query?.Params?.act}) ::`, response)
    return response;
};

export const handleRes = ({ res, config }) => {
    let theres = [];

    if (res?.data?.error) {
        console.log(`ERROR:::`, res?.data?.error[0]?.code);
        switch (res?.data?.error[0].code) {
            case "401":
                // logout();
                // window.location.href = loginUrl;
                break;

            default:
                break;
        }
    } else {
      
            if (res?.data?.result && !res?.data?.result[0].error) {
                theres = res.data.result;
            }
            // const curlCommand = generateCurlCommand(config);
            // localStorage.setItem("api-curl", JSON.stringify(curlCommand, null, 2));
            // localStorage.setItem("api-result", JSON.stringify(theres, null, 2));
        
    }

    return theres;
};

export const handleErr = ({ error, config, redirect }) => {
    console.log("handleErr ::", error);

    let theerror = error;
    if (redirect !== false) {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    console.error("Bad Request:", error.response.data);
                    break;
                case 401:
                    // logout();
                    // window.location.href = loginUrl;
                    console.error("Unauthorized:", error.response.data);
                    break;
                case 403:
                    console.error("Forbidden:", error.response.data);
                    break;
                case 404:
                    console.error("Not Found:", error.response.data);
                    break;
                case 500:
                    console.error("Internal Server Error:", error.response.data);
                    break;
                case 502:
                    // logout();
                    // window.location.href = loginUrl;
                    console.error("Bad Gateway:", error.response.data);
                    break;
                default:
                    console.error("Unhandled error:", error.response.data);
                    break;
            }
        } else if (error.request) {
            // logout();
            // window.location.href = loginUrl;
            console.error("No response received:", error.request);
        } else {
            console.error("Axios error:", error.message);
        }
    }

    if (error.response?.data) {
        theerror = error.response?.data;
    }
    throw theerror;
};
